// TextEllipsis.js
'use strict';
import { css } from 'styled-components';

export const TextEllipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default TextEllipsis;
