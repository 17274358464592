// ResetButtonStyle.js
'use strict';
import { css } from 'styled-components';

export const ResetButtonStyle = css`
  border: none;
  padding: 0px;
  outline: none;
  background: none;
`;

export default ResetButtonStyle;
