// universalServiceWorker.js
// NOTICE:
// 1. For every global variable, put inside of `scope`, so that we can ensure same behavior for true/fallback service worker.
// 2. For functions potentially need `self` or `globalThis`, they are mostly can't be "universal", so don't implement them in this file, put them to `serviceWorker/serviceWorker.js` instead.

export const scope = {
  counter: [],
  criticalCounter: [],
  jointCounter: [],
};
export const setTestCountArrow = count => {
  return (scope.count = count);
};
export const setTestCount = function (count) {
  return (scope.count = count);
};
export const getTestObjectCountArrow = () => {
  return scope.count;
};
export const getTestObjectCount = function () {
  return scope.count;
};
export const getThisValueArrow = function (key) {
  return this[key];
};
export const getThisValue = function (key) {
  return this[key];
};
