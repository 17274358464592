// windowFunctions.js

import trackMixpanel, { EventTypes } from '../resource/mixpanel.js';

/**
 * functions under window object to be protected
 */
const windowFunctionNames = [
  'setTimeout',
  'setInterval',
  'requestAnimationFrame',
  'getComputedStyle',
];

const windowFunctionCaches = windowFunctionNames.reduce(
  (cache, functionName) => ({
    ...cache,
    [functionName]: typeof window !== 'undefined' && window[functionName],
  }),
  {}
);

/**
 * Classes to be protected
 */
const classNames = ['MutationObserver', 'Math'];

/**
 * keys not to be cached and protected
 */
const ignoreKeys = [
  'Math.E',
  'Math.LN10',
  'Math.LN2',
  'Math.LOG10E',
  'Math.LOG2E',
  'Math.PI',
  'Math.SQRT1_2',
  'Math.SQRT2',
];

/**
 * Cache classes and class methonds in form:
 * {
 *   "SomeClass1": classRef,
 *   "SomeClass1#someMethod1": functionRef,
 *   "SomeClass1#someMethod2": functionRef,
 *   "SomeClass2": classRef,
 *   "SomeClass2.someMethod1": staticFunctionRef,
 *   ...
 * }
 */
const classCaches =
  typeof window !== 'undefined'
    ? classNames.reduce((cache, className) => {
        const Class = window[className];
        const newCache = { ...cache, [className]: Class };
        if (typeof Class === 'object') {
          // Static class, like Math
          Object.getOwnPropertyNames(Math).forEach(propertyName => {
            const cacheKey = `${className}.${propertyName}`;
            if (ignoreKeys.indexOf(cacheKey) === -1) {
              newCache[cacheKey] = Class[propertyName];
            }
          });
        } else if (typeof Class === 'function') {
          // Class constructor, like MutationObserver
          Object.keys(Class.prototype).forEach(functionName => {
            const cacheKey = `${className}#${functionName}`;
            if (ignoreKeys.indexOf(cacheKey) === -1) {
              newCache[cacheKey] = Class.prototype[functionName];
            }
          });
        }
        return newCache;
      }, {})
    : {};

/**
 * Get protected setter and getter, where getter will always return `resultCache` and setter will do nothing but log the operation into Mixpanel
 * @param {*} resultCache cached result that getter will return
 * @param {string} functionName functionName to be log into Mixpanel event property
 */
const getAttributes = (resultCache, functionName) => ({
  get: () => resultCache,
  set: value => {
    trackMixpanel({
      type: EventTypes.USER_TRY_OVERRIDE_WINDOW_FUNCTION,
      payload: {
        functionName,
        functionValue: value,
      },
    });
  },
});

/**
 * Protect class and it's methods from being modified
 * @param {string} className Name of the class to be protected
 */
const protectClass = className => {
  if (typeof window === 'undefined') return;

  const Class = window[className];

  if (!Class) return;

  // Protect class override
  Object.defineProperty(
    window,
    className,
    getAttributes(Class, `window.${className}`)
  );

  // Protect prototype
  if (typeof Class === 'object') {
    // Static class, like Math
    Object.getOwnPropertyNames(Math).forEach(propertyName => {
      const cacheKey = `${className}.${propertyName}`;
      const cacheValue = classCaches[cacheKey];
      if (ignoreKeys.indexOf(cacheKey) === -1) {
        Object.defineProperty(
          Class,
          propertyName,
          getAttributes(cacheValue, cacheKey)
        );
      }
    });
  } else if (typeof Class === 'function') {
    // Class constructor, like MutationObserver
    Object.keys(Class.prototype).forEach(functionName => {
      const cacheKey = `${className}#${functionName}`;
      const cacheValue = classCaches[cacheKey];
      if (ignoreKeys.indexOf(cacheKey) === -1) {
        Object.defineProperty(
          Class.prototype,
          functionName,
          getAttributes(cacheValue, cacheKey)
        );
      }
    });
  }
};

/**
 * Begin mutation protections
 */
export const preventMutation = () => {
  if (typeof window === 'undefined') return;

  windowFunctionNames.forEach(functionName => {
    Object.defineProperty(
      window,
      functionName,
      getAttributes(
        windowFunctionCaches[functionName],
        `window.${functionName}`
      )
    );
  });

  classNames.forEach(className => {
    protectClass(className);
  });
};
