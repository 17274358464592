// safeStructuredClone.js
'use strict';

/**
 * Safe structured clone, safer structuredClone(), clone normal values but skip
 * objects need to be `transferred` or not clonable values.
 * If value can successfully be cloned, then options.objectDepth will be ignored.
 * https://developer.mozilla.org/en-US/docs/Web/API/structuredClone
 * https://stackoverflow.com/a/8511350/2605764
 * @param {any} value - value/object need to be clone.
 * @param {Object} [options={}] - options object
 * @param {Number} [options.objectDepth=2] - clone depth for fallback route to avoid circular objects.
 * @returns {any} cloned value/object
 */
const safeStructuredClone = ({ value, options = { objectDepth: 2 } }) => {
  try {
    const cloned = structuredClone(value);
    return cloned;
  } catch (_) {
    //
  }

  const objectDepth = +options.objectDepth || 0;

  if (Array.isArray(value)) {
    if (objectDepth <= 0) {
      return [];
    }
    return value.map(v =>
      safeStructuredClone({
        value: v,
        options: { objectDepth: objectDepth - 1 },
      })
    );
  }

  const isValueAnObject = typeof value === 'object';
  if (!isValueAnObject) {
    return;
  }

  if (objectDepth <= 0) {
    return {};
  }

  return Object.keys(value).reduce((curr, key) => {
    const clonedChild = safeStructuredClone({
      value: value[key],
      options: { objectDepth: objectDepth - 1 },
    });
    if (clonedChild !== undefined) {
      return { ...curr, [key]: clonedChild };
    } else {
      return curr;
    }
  }, {});
};

export default safeStructuredClone;
