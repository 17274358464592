// Sentry.js
const isOnServiceWorker = typeof self !== 'undefined' && self.importScripts;
const tagName = process.env.TAG_NAME;
const branchName = process.env.BRANCH_NAME;
const shortSha = process.env.SHORT_SHA;

let Sentry = null;
let _sessionId = null;
let Sentry2Scope = null; // the second sentry for user bug report

if (isOnServiceWorker) {
  Sentry = self._SENTRY_INSTANCE_;
}

export const setSessionId = ({ sessionId }) => {
  _sessionId = sessionId;
  if (Sentry) {
    Sentry.setTag('session_id', _sessionId);
  }

  if (Sentry2Scope) {
    Sentry2Scope.setTag('session_id', _sessionId);
  }
};

export const initSentry = async ({ sentryConfig = {} } = {}) => {
  if (!Sentry) {
    if (isOnServiceWorker) {
      Sentry = self._SENTRY_INSTANCE_;
    } else {
      const LazySentry = await import('@sentry/browser');
      Sentry = LazySentry;
    }
  }

  if (Sentry.isInitialized()) return Sentry;

  const defaultSentryConfig = {
    dsn: 'https://fb3a44a2f78e4676911d7f323294dcb9@sentry.io/1273292',
    environment: 'default',
    release: `${tagName || branchName?.replace(/[^a-z\d]/gi, '-')}-${shortSha}`,
    level: 'error',
    sampleRate: 0,
    tracesSampleRate: 0,
    // Ref. https://docs.sentry.io/clients/javascript/tips/
    ignoreErrors: [
      // Device memory full.
      /QuotaExceededError/,
      // Safari 12.2 bug. https://bugs.webkit.org/show_bug.cgi?id=197050
      /Connection to Indexed Database server lost/i,
      // User maybe is in incognito mode.
      /No available storage method found/i,

      // sentry error more than 1k
      /Invalid target for t#trigger; must be a DOM node or evented object/i,
      /Non-Error promise rejection captured with keys: error/i,
      /UnknownError: Attempt to iterate a cursor that doesn\/t exist/i,
      /AbortError: The transaction was aborted, so the request cannot be fulfilled/i,

      // checkDrmCapability
      /Unable to create or initialize key session/i,
      /Failed to execute/i,
      /Key system configuration is not supported/i,
      /No compatible source was found for this media/i,
      /KeySystem or Minimum API level not met for Widevine EME/i,
      /The media could not be loaded, either because the server or network failed or because the format is not supported/i,

      // pubsub fetch error
      /TypeError: Load failed/i,
      /TypeError: Failed to fetch/i,
      /post to pubsub error/i,

      // Random plugins/extensions
      /top.GLOBALS/i,
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      /originalCreateNotification/i,
      /canvas.contentDocument/i,
      // Facebook borked
      /fb_xd_fragment/i,
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      /bmi_SafeAddOnload/i,
      /EBCallBackMessageReceived/i,
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      /conduitPage/i,

      // Some error from android webview.
      /vivo_checkMediaPausedReason is not defined/i,

      // GTM error
      /<script> failed to load/i,
      /Object captured as promise rejection with keys: error/i,

      // Player
      /VIDEOJS CODE/i,
      /SHAKA-PLAYER CODE/i,
      /Object captured as exception with keys: code, data, message/i,
      /null is not an object/i,
      /AbortError: The play/i,
      /null is not an object (evaluating \/this.player_.getChild\/)/i,
      /The media playback was aborted due to a corruption problem or because the media used features your browser did not support/i,
      /Cannot read properties/i,
      /The request is not allowed by the user agent/i,
      /play() failed because the user didn\/t interact with the document first/i,
      /AbortError: The operation was aborted/i,
      /e\.player_ is null/i,
      /NotAllowedError: play/i,

      // pusher
      /pusher batch response is not ok/i,

      // service worker
      /service worker fallback to main thread/i,

      // React
      /Uncaught Error: Minified React error/i,
      /Unknown root exit status/i,

      // turnstile
      /turnstile/i,

      // 403 error or 5xx error
      /"status":403|"status":5/i,

      // leaker change style
      /leaker change style/i,

      // firebase
      /Messaging: This browser doesn't support/i,

      // safari ld+json error
      /undefined is not an object \(evaluating 'r\[0\]\["@context"\]'\)/i,
      /undefined is not an object \(evaluating 'r\["@context"\].toLowerCase'\)/i,

      // safari indexedDB error
      /UnknownError: Attempt to get a record from database without an in-progress transaction/i,

      // leaker tried to do somethings
      /leaker tired to do somethings/i,
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
  };

  const {
    init,
    BrowserClient,
    defaultStackParser,
    getDefaultIntegrations,
    makeFetchTransport,
    Scope,
  } = Sentry;

  // main sentry
  const sentryOptions = {
    integrations: getDefaultIntegrations(),
    ...defaultSentryConfig,
    ...sentryConfig,
  };

  if (!isOnServiceWorker) {
    const { browserTracingIntegration, replayIntegration } = Sentry;
    sentryOptions.integrations.push(browserTracingIntegration());
    sentryOptions.integrations.push(replayIntegration());
  }
  init(sentryOptions);

  // second sentry
  // refer to https://docs.sentry.io/platforms/javascript/best-practices/multiple-sentry-instances/
  const sentry2Options = {
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: getDefaultIntegrations().filter(defaultIntegration => {
      return !['BrowserApiErrors', 'Breadcrumbs', 'GlobalHandlers'].includes(
        defaultIntegration.name
      );
    }),
    ...defaultSentryConfig,
    ...sentryConfig,
    dsn: 'https://6215b20088bd4e34b025b17e96a65119@o37462.ingest.sentry.io/4504162520137728',
    sampleRate: 1,
    beforeSend(event) {
      if (event.tags.bug_report) return event;
      return null;
    },
  };
  const client = new BrowserClient(sentry2Options);
  const scope = new Scope();
  scope.setClient(client);
  client.init();
  Sentry2Scope = scope;

  if (_sessionId) {
    Sentry?.setTag('session_id', _sessionId);
    Sentry2Scope?.setTag('session_id', _sessionId);
  }
  return Sentry || {};
};

export const updateSentryConfig = async ({ sentryConfig }) => {
  const sentry = await getSentry();
  sentry.setLevel?.(sentryConfig.level);
  sentry.setTag?.('environment', sentryConfig.environment);
};

export const getSentry = async () => {
  if (isOnServiceWorker) {
    return Sentry || self._SENTRY_INSTANCE_;
  }
  return Sentry || initSentry();
};

/**
 * refer to: https://github.com/getsentry/sentry-javascript/blob/7.0.0/MIGRATION.md#severity-severitylevel-and-severitylevels
 * The new version of sentry deprecated the Severity enum
 * So extract it from old version of sentry
 */
export const Severity = {
  /** JSDoc */
  Fatal: 'fatal',
  /** JSDoc */
  Error: 'error',
  /** JSDoc */
  Warning: 'warning',
  /** JSDoc */
  Log: 'log',
  /** JSDoc */
  Info: 'info',
  /** JSDoc */
  Debug: 'debug',
};

/**
 * return the second sentry for bug report
 * @returns { import('@sentry/browser').Hub }
 */
export const getSentry2Scope = () => {
  return Sentry2Scope;
};

/**
 * Replace some strings that would by default be scrubbing by Sentry.
 * @param {string} {text} - the data to be processed
 * @return {string} processed text.
 */
export const replaceStringsToAvoidDataScrubbing = (text = '') => {
  const sensitiveStrings = [
    ['credentials', 'credentia1s'],
    ['password', 'passw0rd'],
    ['register', 'reg1ster'],
    ['private', 'pr1vate'],
    ['api_key', 'ap1_key'],
    ['config', 'c0nfig'],
    ['login', 'l0gin'],
    ['token', 't0ken'],
    ['auth', 'avth'],
  ];
  return sensitiveStrings.reduce((result, [string, replacement]) => {
    const regex = new RegExp(string, 'gi');
    return result.replace(regex, replacement);
  }, text);
};
